// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/* Position gallery trigger btn */
function galleryTriggerPosition() {
  let $gallery = $('.single-product-top-wrap').find('.flex-viewport');
  let $trigger = $('.single-product-top-wrap').find(
    '.woocommerce-product-gallery__trigger'
  );

  if ($gallery.length) {
    $gallery.append($trigger);
  }
}

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

$(function () {
  $('.animate').addClass('active');
});
// let scroll =
//   window.requestAnimationFrame ||
//   function (callback) {
//     window.setTimeout(callback, 1000 / 60);
//   };

$.fn.isInViewport = function () {
  let viewportTop = $(window).scrollTop();
  let viewportBottom = viewportTop + $(window).height() + 30;
  let elementTop = $(this).offset().top;
  let elementBottom = elementTop + $(this).outerHeight();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

let elsToAnimate = document.querySelectorAll('.animate');

// function isVisible(elem) {
//   let scr = $(window).scrollTop() - 400;
//   return $(elem).offset().top - scr < $(elem).height();
// }

function loop() {
  elsToAnimate.forEach(function (element) {
    if ($(element).isInViewport() && !$(element).hasClass('active')) {
      $(element).addClass('active');
      //if (!element.hasClass('active')) element.classList.add('active');
    }
    if (!$(element).isInViewport() && $(element).hasClass('active')) {
      $(element).removeClass('active');
      //if (element.hasClass('active')) element.classList.remove('active');
    }
  });
  //scroll(loop);
}

// lock/unlock functions
function lockBody() {
  $('html, body').css({
    height: '100%',
    overflow: 'hidden',
  });
}

function unlockBody() {
  $('html, body').css({
    height: '',
    overflow: '',
  });
}

/**
 * Scripts which runs after DOM load
 */
var scrollOut;
$(document).on('ready', function () {
  // if ($('#ship-to-different-address-checkbox').length) {
  //   if (!$('#ship-to-different-address-checkbox').is(':checked'))
  //     $('#ship-to-different-address-checkbox').click();
  // }

  // $(document).on('click', '#ship-to-different-address-checkbox', function () {
  //   !$(this).is(':checked')
  //     ? $('.shipping_address').addClass('show')
  //     : $('.shipping_address').removeClass('show');
  // });

  $('.footer__mobile-sub-btn').on('click', function () {
    $('.footer__mobile-sub-btn').css('display', 'none');
    $('.footer__sub-form').css('display', 'block');
  });

  $('.submenu-toggle').on('click', function () {
    $('.is-accordion-submenu-parent').toggleClass('color-text');
  });

  $('.menu-icon').on('click', function () {
    $('body').toggleClass('lock');
    if ($(this).hasClass('is-active')) {
      lockBody();
    } else {
      unlockBody();
    }
  });

  $('#field_1_6').appendTo($('#gform_wrapper_1 .gform_footer'));
  $('#field_2_3').appendTo($('#gform_wrapper_2 .gform_footer'));
  let shipping = $('.delivery-method-wrap');
  if (shipping.length) {
    let newText = shipping.html().replace('Shipping', '');
    shipping.html(newText);
  }

  $(document).on('click', '.acc-one__link', function () {
    let link = $(this);
    link.toggleClass('active');
    link.parent().find('.acc-one__content').slideToggle();
  });
  let timeout;
  $('.woocommerce').on('change', 'input.qty', function () {
    if (timeout !== undefined) {
      clearTimeout(timeout);
    }
    if ($('body').hasClass('woocommerce-cart')) {
      timeout = setTimeout(function () {
        $("[name='update_cart']").trigger('click');
      }, 1000); // 1 second delay, half a second (500) seems comfortable too
    }
  });

  // $('.basket-btn').on('click', function () {
  //   $('.mini-cart-wrapper').toggleClass('is-active');
  // });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Link for copy page url
   */
  let $temp = $('<input>');
  let $url = $(location).attr('href');
  $('.js-copy-link').on('click', function (event) {
    event.preventDefault();
    $('body').append($temp);
    $temp.val($url).select();
    document.execCommand('copy');
    $temp.remove();
    let $subElem = $(this).find('span');
    $subElem.text('URL copied!').fadeIn();
    setTimeout(function () {
      $subElem.fadeOut();
    }, 3000);
  });

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });
  /**
   * Detect element appearance in viewport
   */
  scrollOut = ScrollOut({
    // offset: function () {
    //   return window.innerHeight - 100;
    // },
    threshold: 0.05,
    once: false,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });
  let lastcard = $('.product-category-cards__card:last-child');
  if (lastcard.hasClass('odd-card')) {
    lastcard.addClass('fullwidht-card');
  }
  /**
   * Hero slider
   */

  $('.hero-slider').slick({
    slidesToShow: 1,
    fade: true,
    cssEase: 'linear',
    speed: 500,
    prevArrow: $('.hero-slider__prev'),
    nextArrow: $('.hero-slider__next'),
    dots: true,
    appendDots: $('.hero-slider__dots'),
  });

  /**
   * Product slider
   */
  $('.product-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    prevArrow: $('.product-slider-prev'),
    nextArrow: $('.product-slider-next'),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  /**
   *  Gallery
   */
  $('.gallery__slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    row: 0,
  });
  $('.gallery__slider-nav > div').click(function () {
    $('.gallery__slider').slick('slickGoTo', $(this).index());
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  // $('#filter-orders').on('keyup', function () {
  //   //get input value
  //   let $value = $(this).val().toLowerCase();

  //   //check for filter or reset
  //   if ($value.length) {
  //     //check all products title
  //     $('.order-data__title').each(function () {
  //       let $this = $(this),
  //         title = $(this).text();

  //       //check if we found a match in title
  //       title.toLowerCase().indexOf($value) < 0
  //         ? $this.parents('.accordion').addClass('hide')
  //         : $this.parents('.accordion').removeClass('hide');
  //     });
  //   } else {
  //     //show all elements if input are empty
  //     $('.accordion.hide').each(function () {
  //       $(this).toggleClass('hide');
  //     });
  //   }
  // });

  $('.order-search').hide();

  $('#filter-orders').on('keyup', function () {
    //get input value
    var $value = $(this).val().toLowerCase();
    if ($value.length) {
      //check all products title
      $('.order-content').each(function () {
        var $this = $(this),
          title = $this.find('.order-data__title').text();
        if (title.toLowerCase().indexOf($value) < 0) {
          if (!$this.parents('.accordion').hasClass('show')) {
            $this.parents('.accordion').addClass('hide');
          }
          if ($('div.accordion').length === $('div.accordion.hide').length) {
            $('.order-search').show();
          } else {
            $('.order-search').hide();
          }
        } else if (title.toLowerCase().indexOf($value) == 0) {
          if ($this.find('order-single-item').length > 1) {
            $this.parents('.accordion').addClass('show');
          } else {
            $this.parents('.accordion').removeClass('hide');
          }
        } else {
          $this.parents('.accordion').removeClass('hide');
          $('.order-search').hide();
        }
      });
    } else {
      //show all elements if input are empty
      $('.accordion.hide').each(function () {
        $(this).toggleClass('hide');
        $('.order-search').hide();
      });
    }
  });

  resizeVideo();

  // add class to style login page
  if (
    ($('.woocommerce-form-login-row').length ||
      $('.woocommerce-form-register').length) &&
    $('.woocommerce-notices-wrapper')
  ) {
    let textError = $('.main-content')
      .find('.woocommerce-error')
      .text()
      .replace('Error:', '');
    $('.main-content').find('.woocommerce-error').text(textError);
    $('.main-content').find('.woocommerce').addClass('reorder-errors');
  }
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  galleryTriggerPosition();

  $('.menu-icon').removeClass('disabled');

  // jQuery code goes here
  if (scrollOut) scrollOut.update();

  $('.message-overlay').on('click', function () {
    $('.message-wrap').fadeOut();
    $(this).fadeOut();
  });

  $('.message-close').on('click', function () {
    $('.message-wrap').fadeOut();
    $('.message-overlay').fadeOut();
  });

  if ($('.flex-control-nav').length) {
    $('.woocommerce-product-gallery').addClass('has-gallery');
  }

  loop();
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  loop();
});
