/* eslint-disable */
/*! modernizr 3.6.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-adownload-backgroundblendmode-backgroundcliptext-canvas-cookies-cssanimations-cssfilters-cssgradients-cssmask-cssscrollbar-flexbox-flexboxlegacy-geolocation-localstorage-objectfit-svg-svgclippaths-svgfilters-touchevents-video-webgl-websockets-setclasses !*/
!(function (e, t, n) {
  function r(e, t) {
    return typeof e === t;
  }
  function o() {
    var e, t, n, o, a, s, i;
    for (var l in b)
      if (b.hasOwnProperty(l)) {
        if (
          ((e = []),
          (t = b[l]),
          t.name &&
            (e.push(t.name.toLowerCase()),
            t.options && t.options.aliases && t.options.aliases.length))
        )
          for (n = 0; n < t.options.aliases.length; n++)
            e.push(t.options.aliases[n].toLowerCase());
        for (o = r(t.fn, 'function') ? t.fn() : t.fn, a = 0; a < e.length; a++)
          (s = e[a]),
            (i = s.split('.')),
            1 === i.length
              ? (Modernizr[i[0]] = o)
              : (!Modernizr[i[0]] ||
                  Modernizr[i[0]] instanceof Boolean ||
                  (Modernizr[i[0]] = new Boolean(Modernizr[i[0]])),
                (Modernizr[i[0]][i[1]] = o)),
            x.push((o ? '' : 'no-') + i.join('-'));
      }
  }
  function a(e) {
    var t = C.className,
      n = Modernizr._config.classPrefix || '';
    if ((_ && (t = t.baseVal), Modernizr._config.enableJSClass)) {
      var r = new RegExp('(^|\\s)' + n + 'no-js(\\s|$)');
      t = t.replace(r, '$1' + n + 'js$2');
    }
    Modernizr._config.enableClasses &&
      ((t += ' ' + n + e.join(' ' + n)),
      _ ? (C.className.baseVal = t) : (C.className = t));
  }
  function s() {
    return 'function' != typeof t.createElement
      ? t.createElement(arguments[0])
      : _
      ? t.createElementNS.call(t, 'http://www.w3.org/2000/svg', arguments[0])
      : t.createElement.apply(t, arguments);
  }
  function i(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function (e, t, n) {
        return t + n.toUpperCase();
      })
      .replace(/^-/, '');
  }
  function l() {
    var e = t.body;
    return e || ((e = s(_ ? 'svg' : 'body')), (e.fake = !0)), e;
  }
  function c(e, n, r, o) {
    var a,
      i,
      c,
      d,
      u = 'modernizr',
      f = s('div'),
      p = l();
    if (parseInt(r, 10))
      for (; r--; )
        (c = s('div')), (c.id = o ? o[r] : u + (r + 1)), f.appendChild(c);
    return (
      (a = s('style')),
      (a.type = 'text/css'),
      (a.id = 's' + u),
      (p.fake ? p : f).appendChild(a),
      p.appendChild(f),
      a.styleSheet
        ? (a.styleSheet.cssText = e)
        : a.appendChild(t.createTextNode(e)),
      (f.id = u),
      p.fake &&
        ((p.style.background = ''),
        (p.style.overflow = 'hidden'),
        (d = C.style.overflow),
        (C.style.overflow = 'hidden'),
        C.appendChild(p)),
      (i = n(f, e)),
      p.fake
        ? (p.parentNode.removeChild(p), (C.style.overflow = d), C.offsetHeight)
        : f.parentNode.removeChild(f),
      !!i
    );
  }
  function d(e, t) {
    return !!~('' + e).indexOf(t);
  }
  function u(e, t) {
    return function () {
      return e.apply(t, arguments);
    };
  }
  function f(e, t, n) {
    var o;
    for (var a in e)
      if (e[a] in t)
        return n === !1
          ? e[a]
          : ((o = t[e[a]]), r(o, 'function') ? u(o, n || t) : o);
    return !1;
  }
  function p(e) {
    return e
      .replace(/([A-Z])/g, function (e, t) {
        return '-' + t.toLowerCase();
      })
      .replace(/^ms-/, '-ms-');
  }
  function v(t, n, r) {
    var o;
    if ('getComputedStyle' in e) {
      o = getComputedStyle.call(e, t, n);
      var a = e.console;
      if (null !== o) r && (o = o.getPropertyValue(r));
      else if (a) {
        var s = a.error ? 'error' : 'log';
        a[s].call(
          a,
          'getComputedStyle returning null, its possible modernizr test results are inaccurate'
        );
      }
    } else o = !n && t.currentStyle && t.currentStyle[r];
    return o;
  }
  function m(t, r) {
    var o = t.length;
    if ('CSS' in e && 'supports' in e.CSS) {
      for (; o--; ) if (e.CSS.supports(p(t[o]), r)) return !0;
      return !1;
    }
    if ('CSSSupportsRule' in e) {
      for (var a = []; o--; ) a.push('(' + p(t[o]) + ':' + r + ')');
      return (
        (a = a.join(' or ')),
        c(
          '@supports (' + a + ') { #modernizr { position: absolute; } }',
          function (e) {
            return 'absolute' == v(e, null, 'position');
          }
        )
      );
    }
    return n;
  }
  function g(e, t, o, a) {
    function l() {
      u && (delete M.style, delete M.modElem);
    }
    if (((a = r(a, 'undefined') ? !1 : a), !r(o, 'undefined'))) {
      var c = m(e, o);
      if (!r(c, 'undefined')) return c;
    }
    for (
      var u, f, p, v, g, h = ['modernizr', 'tspan', 'samp'];
      !M.style && h.length;

    )
      (u = !0), (M.modElem = s(h.shift())), (M.style = M.modElem.style);
    for (p = e.length, f = 0; p > f; f++)
      if (
        ((v = e[f]),
        (g = M.style[v]),
        d(v, '-') && (v = i(v)),
        M.style[v] !== n)
      ) {
        if (a || r(o, 'undefined')) return l(), 'pfx' == t ? v : !0;
        try {
          M.style[v] = o;
        } catch (y) {}
        if (M.style[v] != g) return l(), 'pfx' == t ? v : !0;
      }
    return l(), !1;
  }
  function h(e, t, n, o, a) {
    var s = e.charAt(0).toUpperCase() + e.slice(1),
      i = (e + ' ' + R.join(s + ' ') + s).split(' ');
    return r(t, 'string') || r(t, 'undefined')
      ? g(i, t, o, a)
      : ((i = (e + ' ' + O.join(s + ' ') + s).split(' ')), f(i, t, n));
  }
  function y(e, t, r) {
    return h(e, n, n, t, r);
  }
  var x = [],
    b = [],
    S = {
      _version: '3.6.0',
      _config: {
        classPrefix: '',
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function (e, t) {
        var n = this;
        setTimeout(function () {
          t(n[e]);
        }, 0);
      },
      addTest: function (e, t, n) {
        b.push({ name: e, fn: t, options: n });
      },
      addAsyncTest: function (e) {
        b.push({ name: null, fn: e });
      },
    },
    Modernizr = function () {};
  (Modernizr.prototype = S),
    (Modernizr = new Modernizr()),
    Modernizr.addTest('cookies', function () {
      try {
        t.cookie = 'cookietest=1';
        var e = -1 != t.cookie.indexOf('cookietest=');
        return (
          (t.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT'), e
        );
      } catch (n) {
        return !1;
      }
    }),
    Modernizr.addTest('geolocation', 'geolocation' in navigator),
    Modernizr.addTest(
      'svg',
      !!t.createElementNS &&
        !!t.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect
    );
  var T = !1;
  try {
    T = 'WebSocket' in e && 2 === e.WebSocket.CLOSING;
  } catch (w) {}
  Modernizr.addTest('websockets', T),
    Modernizr.addTest('localstorage', function () {
      var e = 'modernizr';
      try {
        return localStorage.setItem(e, e), localStorage.removeItem(e), !0;
      } catch (t) {
        return !1;
      }
    }),
    Modernizr.addTest('svgfilters', function () {
      var t = !1;
      try {
        t =
          'SVGFEColorMatrixElement' in e &&
          2 == SVGFEColorMatrixElement.SVG_FECOLORMATRIX_TYPE_SATURATE;
      } catch (n) {}
      return t;
    });
  var C = t.documentElement,
    _ = 'svg' === C.nodeName.toLowerCase();
  Modernizr.addTest('canvas', function () {
    var e = s('canvas');
    return !(!e.getContext || !e.getContext('2d'));
  }),
    Modernizr.addTest('video', function () {
      var e = s('video'),
        t = !1;
      try {
        (t = !!e.canPlayType),
          t &&
            ((t = new Boolean(t)),
            (t.ogg = e
              .canPlayType('video/ogg; codecs="theora"')
              .replace(/^no$/, '')),
            (t.h264 = e
              .canPlayType('video/mp4; codecs="avc1.42E01E"')
              .replace(/^no$/, '')),
            (t.webm = e
              .canPlayType('video/webm; codecs="vp8, vorbis"')
              .replace(/^no$/, '')),
            (t.vp9 = e
              .canPlayType('video/webm; codecs="vp9"')
              .replace(/^no$/, '')),
            (t.hls = e
              .canPlayType('application/x-mpegURL; codecs="avc1.42E01E"')
              .replace(/^no$/, '')));
      } catch (n) {}
      return t;
    }),
    Modernizr.addTest('webgl', function () {
      var t = s('canvas'),
        n =
          'probablySupportsContext' in t
            ? 'probablySupportsContext'
            : 'supportsContext';
      return n in t
        ? t[n]('webgl') || t[n]('experimental-webgl')
        : 'WebGLRenderingContext' in e;
    }),
    Modernizr.addTest('adownload', !e.externalHost && 'download' in s('a'));
  var k = S._config.usePrefixes
    ? ' -webkit- -moz- -o- -ms- '.split(' ')
    : ['', ''];
  (S._prefixes = k),
    Modernizr.addTest('cssgradients', function () {
      for (
        var e,
          t = 'background-image:',
          n = 'gradient(linear,left top,right bottom,from(#9f9),to(white));',
          r = '',
          o = 0,
          a = k.length - 1;
        a > o;
        o++
      )
        (e = 0 === o ? 'to ' : ''),
          (r += t + k[o] + 'linear-gradient(' + e + 'left top, #9f9, white);');
      Modernizr._config.usePrefixes && (r += t + '-webkit-' + n);
      var i = s('a'),
        l = i.style;
      return (l.cssText = r), ('' + l.backgroundImage).indexOf('gradient') > -1;
    });
  var E = 'CSS' in e && 'supports' in e.CSS,
    P = 'supportsCSS' in e;
  Modernizr.addTest('supports', E || P);
  var z = {}.toString;
  Modernizr.addTest('svgclippaths', function () {
    return (
      !!t.createElementNS &&
      /SVGClipPath/.test(
        z.call(t.createElementNS('http://www.w3.org/2000/svg', 'clipPath'))
      )
    );
  });
  var j = (S.testStyles = c);
  Modernizr.addTest('touchevents', function () {
    var n;
    if ('ontouchstart' in e || (e.DocumentTouch && t instanceof DocumentTouch))
      n = !0;
    else {
      var r = [
        '@media (',
        k.join('touch-enabled),('),
        'heartz',
        ')',
        '{#modernizr{top:9px;position:absolute}}',
      ].join('');
      j(r, function (e) {
        n = 9 === e.offsetTop;
      });
    }
    return n;
  }),
    j(
      '#modernizr{overflow: scroll; width: 40px; height: 40px; }#' +
        k
          .join('scrollbar{width:10px} #modernizr::')
          .split('#')
          .slice(1)
          .join('#') +
        'scrollbar{width:10px}',
      function (e) {
        Modernizr.addTest(
          'cssscrollbar',
          'scrollWidth' in e && 30 == e.scrollWidth
        );
      }
    );
  var N = 'Moz O ms Webkit',
    R = S._config.usePrefixes ? N.split(' ') : [];
  S._cssomPrefixes = R;
  var L = function (t) {
    var r,
      o = k.length,
      a = e.CSSRule;
    if ('undefined' == typeof a) return n;
    if (!t) return !1;
    if (
      ((t = t.replace(/^@/, '')),
      (r = t.replace(/-/g, '_').toUpperCase() + '_RULE'),
      r in a)
    )
      return '@' + t;
    for (var s = 0; o > s; s++) {
      var i = k[s],
        l = i.toUpperCase() + '_' + r;
      if (l in a) return '@-' + i.toLowerCase() + '-' + t;
    }
    return !1;
  };
  S.atRule = L;
  var O = S._config.usePrefixes ? N.toLowerCase().split(' ') : [];
  S._domPrefixes = O;
  var A = { elem: s('modernizr') };
  Modernizr._q.push(function () {
    delete A.elem;
  });
  var M = { style: A.elem.style };
  Modernizr._q.unshift(function () {
    delete M.style;
  }),
    (S.testAllProps = h),
    (S.testAllProps = y),
    Modernizr.addTest('cssanimations', y('animationName', 'a', !0)),
    Modernizr.addTest('backgroundcliptext', function () {
      return y('backgroundClip', 'text');
    }),
    Modernizr.addTest('cssfilters', function () {
      if (Modernizr.supports) return y('filter', 'blur(2px)');
      var e = s('a');
      return (
        (e.style.cssText = k.join('filter:blur(2px); ')),
        !!e.style.length && (t.documentMode === n || t.documentMode > 9)
      );
    }),
    Modernizr.addTest('flexbox', y('flexBasis', '1px', !0)),
    Modernizr.addTest('flexboxlegacy', y('boxDirection', 'reverse', !0)),
    Modernizr.addTest('cssmask', y('maskRepeat', 'repeat-x', !0));
  var V = (S.prefixed = function (e, t, n) {
    return 0 === e.indexOf('@')
      ? L(e)
      : (-1 != e.indexOf('-') && (e = i(e)), t ? h(e, t, n) : h(e, 'pfx'));
  });
  Modernizr.addTest('backgroundblendmode', V('backgroundBlendMode', 'text')),
    Modernizr.addTest('objectfit', !!V('objectFit'), {
      aliases: ['object-fit'],
    }),
    o(),
    a(x),
    delete S.addTest,
    delete S.addAsyncTest;
  for (var G = 0; G < Modernizr._q.length; G++) Modernizr._q[G]();
  e.Modernizr = Modernizr;
})(window, document);
